import * as $ from 'jquery';
import axios from "axios";
const BASE_URL = '.netlify/functions/sendmail'

const txtName = $("#txtName")
const txtContact = $("#txtContact")
const lstAttending = $("#lstAttending")
const lstTransport = $("#lstTransport")
const txtAdditionalInfo = $("#txtAdditionalInfo")

function sendMail() {  
    const data = {
      name:txtName.val(),
      contact:txtContact.val(),
      attending:lstAttending.val(),
      transport:lstTransport.val(),
      info:txtAdditionalInfo.val()
    }
    return axios.post(BASE_URL, data)
      
  }

  $('form').on( "submit",(event)=> {
    event.preventDefault();
    const btnSubmit = $('#btnSubmit')
    // disable button
    btnSubmit.prop("disabled", true);  
    btnSubmit.addClass( "button--loading" );

    const submissionState = $("#divSubmissionState")
    submissionState.slideUp()
    submissionState.removeClass('failure') 
    submissionState.removeClass('success')         
    submissionState.removeClass('attending')         
    submissionState.removeClass('not-attending')         


    
    const isAttending = lstAttending.val()==='attending'

    sendMail()
    .then(()=>{
      submissionState.addClass('success')         
      submissionState.addClass(isAttending?'attending':'not-attending')         
      txtName.val(''),
      txtContact.val(''),
      lstAttending.val(''),
      lstTransport.val(''),
      txtAdditionalInfo.val('')
      lstAttending.trigger( "change" );
    })
    .catch(err=>{
      submissionState.addClass('failure') 
      console.log(err)
    })
    .finally(()=>{
      submissionState.slideDown()
      btnSubmit.prop("disabled", false);
      btnSubmit.removeClass( "button--loading" );
    })
  })


